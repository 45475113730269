export default {
  // Style prefix
  stylePrefix: 'css-',

  // Custom CSS string to render on top
  staticRules: '',

  // Default CSS style
  rules: []
};

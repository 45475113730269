export default {
  // Specify the element to use as a container, string (query) or HTMLElement
  // With the empty value, nothing will be rendered
  appendTo: '',

  // Append blocks to canvas on click
  appendOnClick: 0,

  blocks: []
};
